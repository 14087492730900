import { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Facebook, Instagram, TwitterX, Tiktok, Linkedin } from 'react-bootstrap-icons';
import Scan from '../assets/scan.png';
import Result1 from '../assets/result1.png';
import Result2 from '../assets/result2.png';
import Camera from '../assets/camera.png';
import GetGooglePlay from '../assets/get-google-play.png';
import GetAppleStore from '../assets/get-apple-store.svg';
import Glasses from '../assets/glasses.png';
import Chat from '../assets/chat.svg';
import Data from '../data/Data';
import Support from '../components/Support';
import F6s from '../assets/f6s-logo-square.svg';

gsap.registerPlugin(ScrollTrigger);

function Home() {
  const actualYear = new Date().getFullYear();

  useEffect(() => {
    gsap.fromTo('.blurred-card-1',
      { y: '-90vh', x: '70vw' },
      {
        y: '-80vh',
        scrollTrigger: {
          trigger: '#hero',
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
      }
    );

    gsap.fromTo('.blurred-card-2',
      { y: '-60vh', x: '50vw' },
      {
        y: '-45vh',
        scrollTrigger: {
          trigger: '#hero',
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
      }
    );

    gsap.fromTo('.blurred-card-3',
      { y: '-40vh', x: '79vw' },
      {
        y: '-25vh',
        scrollTrigger: {
          trigger: '#hero',
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
      }
    );
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <section className='container-fluid' id='hero'>
        <div className='row'>
          <div className='col-lg-6 d-flex align-items-center'>
            <div className='mx-5'>
              <h1>Elevate Your Style with <span>Outfino</span></h1>
              <p className='my-5'>Discover Fashion Designed for You.</p>
              <div className='d-md-flex mt-5 justify-content-center justify-content-lg-start'>
                <img src={GetGooglePlay} className='get-google-play me-2 mb-2' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.outfino.mobile')} alt='Get it on Google Play' />
                <img src={GetAppleStore} className='get-apple-store me-2 mb-2' onClick={() => window.open('https://apps.apple.com/hu/app/outfino/id6736884918?l=hu')} alt='Download on the App Store' />
              </div>
            </div>
          </div>
          {isDesktop && (
            <div className='col-lg-6 d-none d-lg-flex justify-content-center align-items-center'>
              <div className='video'>
                <video autoPlay loop controls={false} muted>
                  <source src='https://api.trophien.com/files/shared/Projektek/Stylevise/outfino-commercial.mp4' type="video/mp4" />
                </video>
              </div>
            </div>
          )}
        </div>

        <div className='blurred-card-1 d-none d-lg-block'>
          <div>Throw in a lightweight scarf for extra style points.</div>
        </div>
        <div className='blurred-card-2 d-none d-lg-block'>
          <div>Consider khaki shorts for warmer days.</div>
        </div>
        <div className='blurred-card-3 d-none d-lg-block'>
          Mix it up with some boat shoes to lean into the nautical theme
        </div>
      </section>

      <section className='container-fluid' id='camera'>
        <img src={Camera} className='camera-bg' alt='camera' />
        <div className='row'>
          <div className='col-lg-6 d-flex align-items-center'>
            <div className='mx-5'>
              <h2>Scan Your Style</h2>
              <p className='mt-5'>
                Effortlessly capture and analyze your unique style wherever you are with our quick scan feature. Anytime, anywhere—discover personalized fashion insights designed just for you!
              </p>
            </div>
          </div>
          <div className='col-lg-6 d-flex justify-content-center align-items-center'>
            <img src={Scan} className='img-fluid' alt='scanning' />
          </div>
        </div>
      </section>

      <section className='container-fluid' id='summary'>
        <img src={Glasses} className='glasses-bg' alt='glasses' />
        <div className='row'>
          <div className='col-lg-6 d-flex align-items-center'>
            <div className='mx-5'>
              <h2>Let's get to know your actual style</h2>
              <p>Discover styles that match your personality. We analyze your preferences to help you find outfits that reflect your unique taste.</p>
            </div>
          </div>
          <div className='col-lg-6 d-flex justify-content-center align-items-center'>
            <img src={Result1} className='img-fluid' alt='scanning' />
          </div>
        </div>
      </section>

      <section className='container-fluid' id='ai'>
        <img src={Chat} className='chat-bg' alt='chat' />
        <div className='row'>
          <div className='col-lg-6 d-flex align-items-center'>
            <div className='mx-5'>
              <h2>Get advice from the AI</h2>
              <p>Receive personalized fashion tips instantly. Let our AI suggest outfits tailored just for you.</p>
            </div>
          </div>
          <div className='col-lg-6 d-flex justify-content-center align-items-center'>
            <img src={Result2} className='img-fluid' alt='scanning' />
          </div>
        </div>
      </section>

      <Support />

      <section id='awards' className='container'>
        <div className='row d-flex justify-content-center text-center'>
          <div className='col-md-8'>
            <h2 className='mb-5'>Our awards</h2>
            <div className='row'>
              <div className='col-md-6'>
                <div className='link' onClick={() => window.open('https://www.f6s.com/companies/sustainability/hungary/co')}>
                  <img src={F6s} className='f6s' alt='f6s - 5th Cyber Security Startup of July 2024' onClick={() => window.open('https://www.f6s.com/companies/sustainability/hungary/co')} />
                  <p>13 top Sustainability companies and startups in Hungary in October 2024</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='link' onClick={() => window.open('https://www.f6s.com/companies/software-development/hungary/budapest/co')}>
                  <img src={F6s} className='f6s' alt='f6s - 5th Cyber Security Startup of July 2024' onClick={() => window.open('https://www.f6s.com/companies/software-development/hungary/budapest/co')} />
                  <p>28 top Software Development companies and startups in Budapest in October 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="container-fluid">
        <div className="row pb-5 mx-5">
          <div className="col-md-4 order-last order-md-first mt-5">
            <div className='d-flex social-links mb-4'>
              <Facebook onClick={() => window.open('https://www.facebook.com/profile.php?id=61566263469126')} />
              <Instagram onClick={() => window.open('https://www.instagram.com/outfino.io/')} />
              <TwitterX onClick={() => window.open('https://x.com/outfino_io')} />
              <Tiktok onClick={() => window.open('https://www.tiktok.com/@outfino.io')} />
              <Linkedin onClick={() => window.open('https://www.linkedin.com/company/outfino')} />
            </div>
            <p className='mt-1'><a href={`mailto:${Data.supportEmail}`}>{Data.supportEmail}</a><br /><b>Ubives Kft.</b> - {actualYear}</p>
            <p><b>VAT Number:</b> HU32624034<br /><b>Company Reg. No.:</b> 01-09-434045<br />1145 Budapest, Tallér str 15.</p>
          </div>
          <div className="col-md-4 links mt-5">
            <h2>Useful links</h2>
            <ul className="mt-3">
              <li>
                <a href="#camera">Scan your friends</a>
              </li>
              <li>
                <a href="#summary">Your actual style</a>
              </li>
              <li>
                <a href="#ai">Advice from AI</a>
              </li>
              <li>
                <a href="/support">Support</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 links mt-5">
            <h2>Documents</h2>
            <ul className="mt-3">
              <li>
                <a href="/" target='_blank'>GDPR</a>
              </li>
              <li>
                <a href="/privacy-policy" target='_blank'>Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
